import React, {Component} from 'react'

const QuestionRow = (props) => {
	const { rowKey, rowIndex, columns, rowLabel, handleQuestionCheck } = props;
	return (
		<div className={`row mb-5 box Box_${rowKey}`}>
			<QuestionColumn
				colSize="col-lg-4"
				column={columns.column_1}
				rowIndex={rowIndex}
				columnIndex="column_1"
				handleQuestionCheck={handleQuestionCheck}
			/>
			<QuestionColumn
				colSize="col-lg-3"
				column={columns.column_2}
				rowIndex={rowIndex}
				columnIndex="column_2"
				handleQuestionCheck={handleQuestionCheck}
			/>
			<QuestionColumn
				colSize="col-lg-3"
				column={columns.column_3}
				rowIndex={rowIndex}
				columnIndex="column_3"
				handleQuestionCheck={handleQuestionCheck}
			/>
			<QuestionCount columns={columns} rowLabel={rowLabel} />
		</div>
	);
};

const QuestionColumn = (props) => {
	const { colSize, column, rowIndex, columnIndex, handleQuestionCheck } = props;
	return (
		<div className={`box-column ${colSize}`}>
			<p className="fw-bold">{column.question}</p>
			<ColumnOptions
				options={column.options}
				rowIndex={rowIndex}
				columnIndex={columnIndex}
				handleQuestionCheck={handleQuestionCheck}
			/>
		</div>
	);
};

const ColumnOptions = (props) => {
	const { options, rowIndex, columnIndex, handleQuestionCheck } = props;
	const renderOptions = []
	for (let optionIndex in options) {
		renderOptions.push(
			<label key={optionIndex}>
				<input
					data-row-index={rowIndex}
					data-column-index={columnIndex}
					data-option-index={optionIndex}
					type="checkbox"
					value={options[optionIndex].value}
					checked={options[optionIndex].checked}
					onChange={handleQuestionCheck}
				/>{" "}
				{options[optionIndex].value}
			</label>
		)
	}
	return renderOptions;
};
const QuestionCount = (props) => {
	const { columns, rowLabel } = props;
	const rowCount = Object.keys(columns).reduce((total, key) => {
		const options = columns[key].options
		const checked = Object.keys(options).filter((index) => {
			return options[index].checked === true;
		});
		return total + checked.length;
	}, 0);
	return (
		<div className="col-lg-2 box-column">
			<p>
				<strong>Total number checked in {rowLabel}: </strong>
			</p>
			<div className="box-score">{rowCount ? rowCount : ''}</div>
		</div>
	);
};

class Questions extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		const { questions, handleQuestionCheck, showResult } = this.props;
		const renderQuestions = []
		for (let index in questions) {
			renderQuestions.push(
				<QuestionRow
					key={questions[index].id}
					rowKey={questions[index].id}
					rowIndex={index}
					columns={questions[index].columns}
					rowLabel={questions[index].label}
					handleQuestionCheck={handleQuestionCheck}
				/>
			)
		}
		return (
			<div className="col-12">
				<h1 className="mt-5">Career Clusters Interest Survey</h1>
				<p><strong>Directions:</strong> Circle the items in each box that best describe you. You may make as many or as few circles in each box as you choose. Add up the number of circles in each box. Look to see which three boxes have the highest numbers. Find the corresponding Career Clusters on the pages immediately following this survey to see which Career Clusters you may want to explore.</p>
				<p><em><strong>Note:</strong> This survey does not make any claim of statistical reliability and has not been normed. It is intended for use as a guidance tool to generate discussion regarding careers and is valid for that purpose.</em></p>
				<div className="mt-5">
					{renderQuestions}
				</div>
				<p><em><strong>Disclaimer:</strong> Your interest may change over time. These survey results are intended to assist you with informal career exploration. Consider more formal assessments and other resources or services to help you plan your career. This survey does not make any claims of statistical reliability.</em></p>
				<button className="btn btn-primary mb-5" onClick={showResult} >FINISH</button>
			</div>
		)
	}
}

export default Questions;
