import React, { Component } from "react";

class Validate extends Component {
	initialState = {
		password: "",
	};

	state = this.initialState;

	handleChange = (event) => {
		const { value } = event.target;
		this.setState((prevState) => ({
			...prevState,
			password: value,
		}));
	};

	render() {
		return (
			<form
				className="row row-cols-lg-auto g-3 align-items-center justify-content-center"
				action=""
				onSubmit={this.props.handleSubmit}
			>
				<div className="col-12">
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Password"
						className="form-control"
						value={this.state.password}
						required={true}
						onChange={this.handleChange}
					/>
				</div>
				<div className="col-12">
					<button type="submit" className="btn btn-primary">
						Save
					</button>
				</div>
			</form>
		);
	}
}

const Head = () => {
	return (
		<tr>
			<th>ID</th>
			<th>Label</th>
			<th>Job</th>
			<th>Description</th>
			<th>Column 1</th>
			<th>Column 2</th>
			<th>Column 3</th>
		</tr>
	);
};
const Rows = (props) => {
	const { questions, handleUpdateRow, handleUpdateColumn } = props;
	const renderRows = [];
	for (let key in questions) {
		const columns = questions[key].columns;
		const columns_string = {};
		for (var column_key in columns) {
			const options = columns[column_key].options;
			const strings = Object.keys(options).map((option_key) => {
				return options[option_key].value;
			});
			columns_string[column_key] = strings;
		}

		renderRows.push(
			<tr key={key}>
				<td>
					<input
						type="text"
						className="form-control"
						value={questions[key].id}
						data-row-index={key}
						data-name="id"
						onChange={handleUpdateRow}
					/>
				</td>
				<td>
					<input
						type="text"
						className="form-control"
						value={questions[key].label}
						data-row-index={key}
						data-name="label"
						onChange={handleUpdateRow}
					/>
				</td>
				<td>
					<textarea
						className="form-control"
						value={questions[key].job}
						data-row-index={key}
						data-name="job"
						onChange={handleUpdateRow}
					></textarea>
				</td>
				<td>
					<textarea
						className="form-control"
						value={questions[key].description}
						data-row-index={key}
						data-name="description"
						onChange={handleUpdateRow}
					></textarea>
				</td>
				<td>
					<input
						type="text"
						className="form-control mb-2"
						value={questions[key].columns.column_1.question}
						data-row-index={key}
						data-column-index="column_1"
						data-name="question"
						onChange={handleUpdateColumn}
					/>
					<textarea
						className="form-control"
						value={columns_string.column_1.join("\r\n")}
						data-row-index={key}
						data-column-index="column_1"
						data-name="options"
						onChange={handleUpdateColumn}
					></textarea>
				</td>
				<td>
					<input
						type="text"
						className="form-control mb-2"
						value={questions[key].columns.column_2.question}
						data-row-index={key}
						data-column-index="column_2"
						data-name="question"
						onChange={handleUpdateColumn}
					/>
					<textarea
						className="form-control"
						value={columns_string.column_2.join("\r\n")}
						data-row-index={key}
						data-column-index="column_2"
						data-name="options"
						onChange={handleUpdateColumn}
					></textarea>
				</td>
				<td>
					<input
						type="text"
						className="form-control mb-2"
						value={questions[key].columns.column_3.question}
						data-row-index={key}
						data-column-index="column_3"
						data-name="question"
						onChange={handleUpdateColumn}
					/>
					<textarea
						className="form-control"
						value={columns_string.column_3.join("\r\n")}
						data-row-index={key}
						data-column-index="column_3"
						data-name="options"
						onChange={handleUpdateColumn}
					></textarea>
				</td>
			</tr>
		);
	}
	return renderRows;
};

const AddRow = (props) => {
	const { handleAddRow } = props;
	return (
		<div className="text-end">
			<button className="btn btn-secondary" onClick={handleAddRow}>
				Add row
			</button>
		</div>
	);
};

class Edit extends Component {
	initialState = {
		questions: {},
	};

	state = this.initialState;

	blankRow = (newIndex) => {
		return {
			id: newIndex,
			label: "Box " + newIndex,
			columns: {
				column_1: {
					question: "Activities that describe what I like to do:",
					options: {},
				},
				column_2: {
					question: "Personal qualities that describe me:",
					options: {},
				},
				column_3: {
					question: "School subjects that I like:",
					options: {},
				},
			},
			job: "",
			description: "",
		};
	};

	componentDidMount = async () => {
	    const requestOptions = {
	        method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({
	        	bypass_check: true
	        })
	    };
	    const response = await fetch(
	    	process.env.REACT_APP_API_URL + "get-questions.php",
	    	requestOptions
	    );
	    const data = await response.json();
	    if (data.hasOwnProperty("error")) {
	    	alert(data.error);
	    	return;
	    }
	    this.setState(prevState => ({
	    	...prevState,
	    	questions: data.success.questions
	    }));
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		const password = event.target.password.value;
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				password: password,
				questions: this.state.questions
			}),
		};
		const response = await fetch(
			process.env.REACT_APP_API_URL + "edit-questions.php",
			requestOptions
		);
		const data = await response.json();
		if (data.hasOwnProperty("error")) {
			alert(data.error);
			return;
		}
		alert(data.success);
	};

	handleUpdateRow = (event) => {
		const {rowIndex, name} = event.target.dataset
		const {value} = event.target
		this.setState(prevState => ({
			...prevState,
			questions: {
				...prevState.questions,
				[rowIndex]: {
					...prevState.questions[rowIndex],
					[name]: value
				},
			},
		}))
	};

	handleUpdateColumn = (event) => {
		const {rowIndex, columnIndex, name} = event.target.dataset
		const {value} = event.target
		let formatValue = value;
		if (name === 'options') {
			formatValue = {}
			const options_arr = value.split(/\r?\n/)
			options_arr.forEach((option, index) => {
				formatValue[index] = {
					value: option,
					checked: false
				}
			});
		}
		this.setState(prevState => ({
			...prevState,
			questions: {
				...prevState.questions,
				[rowIndex]: {
					...prevState.questions[rowIndex],
					columns: {
						...prevState.questions[rowIndex].columns,
						[columnIndex]: {
							...prevState.questions[rowIndex].columns[columnIndex],
							[name]: formatValue
						}
					}
				},
			},
		}))
	};

	handleAddRow = () => {
		const newIndex = Object.keys(this.state.questions).length + 1;
		this.setState((prevState) => ({
			...prevState,
			questions: {
				...prevState.questions,
				[newIndex]: this.blankRow(newIndex),
			},
		}));
	};

	render() {
		return (
			<div className="col-12 text-center">
				<div className="edit-table table-responsive">
					<table className="table">
						<thead>
							<Head />
						</thead>
						<tbody>
							<Rows
								questions={this.state.questions}
								handleUpdateRow={this.handleUpdateRow}
								handleUpdateColumn={this.handleUpdateColumn}
							/>
						</tbody>
					</table>
					<AddRow handleAddRow={this.handleAddRow} />
					<Validate handleSubmit={this.handleSubmit} />
				</div>
			</div>
		);
	}
}

export default Edit;
