import React, { Component } from "react";

const InputField = (props) => {
	const { attributes, handleChange } = props;
	const renderInput = []
	
	for (var name in attributes) {
		const placeholder = (attributes[name].required) ? attributes[name].placeholder+' *' : attributes[name].placeholder
		renderInput.push(
			<div className="wrap-input2" key={name}>
				<input
					className={`input2 ${attributes[name].value ? 'has-val' : ''}`}
					type={attributes[name].type}
					pattern={attributes[name].pattern}
					id={attributes[name].id}
					name={name}
					value={attributes[name].value}
					required={attributes[name].required}
					onChange={handleChange}
				/>
				<span className="focus-input2" data-placeholder={placeholder}></span>
			</div>
		)
	}
	return renderInput
};

const SubmitButton = (props) => {
	const { label, handleFormSubmit } = props;

	return (
		<div className="container-contact2-form-btn">
			<div className="wrap-contact2-form-btn">
				<div className="contact2-form-bgbtn"></div>
				<button className="contact2-form-btn" onClick={handleFormSubmit} >
					<strong>{label}</strong>
				</button>
			</div>
		</div>
	)
};

const CompareButton = (props) => {
	const {compareMode} = props
	return(
		<div className="text-center mt-4 mb-4">
			<button className="btn btn-secondary btn-sm" onClick={compareMode} >So sánh kết quả</button>
		</div>
	)
}

class Form extends Component {
	initialState = {
		fullname: {
			type: "text",
			id: "fullname",
			placeholder: "HỌ VÀ TÊN",
			value: "",
			pattern: undefined,
			required: true,
		},
		email: {
			type: "email",
			id: "email",
			placeholder: "EMAIL",
			value: "",
			pattern: undefined,
			required: true,
		},
		phone: {
			type: "tel",
			id: "phone",
			placeholder: "SỐ ĐIỆN THOẠI",
			value: "",
			pattern: "[0]{1}[0-9]{9}",
			required: true,
		},
	};

	state = this.initialState;

	componentDidMount = () => {
		if (localStorage.getItem('survey_user_id')) {
			this.setState(prevState => ({
				...prevState,
				fullname: {
					...prevState.fullname,
					value: localStorage.getItem('survey_fullname'),
				},
				email: {
					...prevState.email,
					value: localStorage.getItem('survey_email'),
				},
				phone: {
					...prevState.phone,
					value: localStorage.getItem('survey_phone'),
				},
			}), () => {
				const formData = {
					fullname: this.state.fullname.value,
					email: this.state.email.value,
					phone: this.state.phone.value,
				}
				this.props.handleFormCallback(formData);
			})
		}
	}

	handleChange = (event) => {
		const { name, value } = event.target;

		this.setState(prevState => ({
			...prevState,
			[name]: {
				...prevState[name],
				value: value,
			},
		}), () => {
			const formData = {
				fullname: this.state.fullname.value,
				email: this.state.email.value,
				phone: this.state.phone.value,
			}
			this.props.handleFormCallback(formData);
		});
		
	};

	render() {
		return (
			<div className="col-sm-8 offset-sm-2">
				<img className="img-fluid" src="https://careersurvey.ivyglobalschool.org/images/banner.jpg" alt="" />
				<h3 className="text-center mt-4 mb-3">Career Clusters Interest Survey - Ivy Global School</h3>
				<form action="" onSubmit={this.props.handleFormSubmit}>
					<InputField
						attributes={this.state}
						handleChange={this.handleChange}
					/>
					<SubmitButton label="BẮT ĐẦU" />
				</form>
				<CompareButton compareMode={this.props.compareMode} />
			</div>
		);
	}
}

export default Form;
