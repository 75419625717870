import React, { Component } from "react";
import "./App.css";
import Form from "./Form";
import Questions from "./Questions";
import Edit from "./Edit";
import Result from "./Result";
import Compare from "./Compare";

class App extends Component {
	initialState = {
		isDisabled: false,
		editMode: false,
		showResult: false,
		compareMode: false,
		questions: {},
		user_info: {
			fullname: "",
			email: "",
			phone: "",
		},
	};

	state = this.initialState;

	handleFormCallback = (formData) => {
		this.setState((prevState) => ({
			...prevState,
			user_info: {
				fullname: formData.fullname,
				email: formData.email,
				phone: formData.phone,
			},
		}));
	};

	handleFormSubmit = async (event) => {
		event.preventDefault();
		const fullname = this.state.user_info.fullname;
		const email = this.state.user_info.email;
		const phone = this.state.user_info.phone;
		const survey_user_id = localStorage.getItem("survey_user_id");
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				fullname: fullname,
				email: email,
				phone: phone,
				survey_user_id: survey_user_id,
			}),
		};
		const response = await fetch(
			process.env.REACT_APP_API_URL + "get-questions.php",
			requestOptions
		);
		const data = await response.json();
		if (data.hasOwnProperty("error")) {
			alert(data.error);
			return;
		}
		if (data.success.hasOwnProperty("survey_user_id")) {
			localStorage.setItem("survey_user_id", data.success.survey_user_id);
			localStorage.setItem("survey_fullname", fullname);
			localStorage.setItem("survey_email", email);
			localStorage.setItem("survey_phone", phone);
		}
		this.setState((prevState) => ({
			...prevState,
			questions: data.success.questions,
		}));
	};

	handleQuestionCheck = (event) => {
		if (this.state.isDisabled === true) return;
		const { checked } = event.target;
		const { rowIndex, columnIndex, optionIndex } = event.target.dataset;
		this.setState(
			(prevState) => ({
				...prevState,
				questions: {
					...prevState.questions,
					[rowIndex]: {
						...prevState.questions[rowIndex],
						columns: {
							...prevState.questions[rowIndex].columns,
							[columnIndex]: {
								...prevState.questions[rowIndex].columns[
									columnIndex
								],
								options: {
									...prevState.questions[rowIndex].columns[
										columnIndex
									].options,
									[optionIndex]: {
										...prevState.questions[rowIndex]
											.columns[columnIndex].options[
											optionIndex
										],
										checked: checked,
									},
								},
							},
						},
					},
				},
			}),
			async () => {
				const fullname = this.state.user_info.fullname;
				const email = this.state.user_info.email;
				const phone = this.state.user_info.phone;
				const answers = this.state.questions;
				const survey_user_id = localStorage.getItem("survey_user_id");
				const requestOptions = {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						fullname: fullname,
						email: email,
						phone: phone,
						answers: answers,
						survey_user_id: survey_user_id,
					}),
				};
				const response = await fetch(
					process.env.REACT_APP_API_URL + "save-result.php",
					requestOptions
				);
				const data = await response.json();
				if (data.hasOwnProperty("error")) {
					alert(data.error);
					return;
				}
				// alert(data.success);
			}
		);
	};

	showResult = () => {
		this.setState((prevState) => ({
			...prevState,
			showResult: true,
		}));
	};

	compareMode = () => {
		this.setState((prevState) => ({
			...prevState,
			compareMode: true,
		}));
	};

	surveyMode = () => {
		this.setState((prevState) => ({
			...prevState,
			isDisabled: false,
			editMode: false,
			showResult: false,
			compareMode: false,
		}));
	};

	render() {
		let app;

		if (Object.keys(this.state.questions).length > 0) {
			app = (
				<Questions
					questions={this.state.questions}
					handleQuestionCheck={this.handleQuestionCheck}
					showResult={this.showResult}
				/>
			);
		} else {
			app = (
				<Form
					handleFormCallback={this.handleFormCallback}
					handleFormSubmit={this.handleFormSubmit}
					compareMode={this.compareMode}
				/>
			);
		}

		if (this.state.editMode === true) {
			app = <Edit />;
		}

		if (this.state.showResult === true) {
			app = <Result searchName={this.state.user_info.fullname} compareState={this.state.compareMode} compareMode={this.compareMode} />;
		}

		if (this.state.compareMode === true) {
			app = <Compare searchName={this.state.user_info.fullname} compareState={this.state.compareMode} surveyMode={this.surveyMode} />;
		}

		return (
			<div className="container">
				<div className="row">{app}</div>
			</div>
		);
	}
}

export default App;
